
module.exports = {
  GTM_ID: 'GTM-PJFXRTP',
  // GTM_HOST_ORIGIN: 'https://gtm.lylli.se', // Not supported in gatsby-config.js
  ICON: '🇸🇪',
  SITE_URL: 'https://account.lylli.se',
  SENTRY_DSN: 'https://d0ddf42e87e7425f8d547fd4742a06b6@o360860.ingest.sentry.io/6003205',
  CURRENCY: 'kr',
  STORELOGOS: {
    APPLE: {
      alt: 'App Store logo',
      href: 'https://itunes.apple.com/se/app/id1036395238',
      imageIdSuffix: '-app-store-image',
      linkIdSuffix: '-app-store-link',
    },
    GOOGLE: {
      alt: 'Google Play logo',
      href: 'https://play.google.com/store/apps/details?id=se.boksnok.android',
      imageIdSuffix: '-google-play-image',
      linkIdSuffix: '-google-play-link',
    },
    APPLE_LIGHT: {
      alt: 'App Store logo',
      href: 'https://itunes.apple.com/se/app/id1036395238',
      imageIdSuffix: '-app-store-image',
      linkIdSuffix: '-app-store-link',
    },
    GOOGLE_LIGHT: {
      alt: 'Google Play logo',
      href: 'https://play.google.com/store/apps/details?id=se.boksnok.android',
      imageIdSuffix: '-google-play-image',
      linkIdSuffix: '-google-play-link',
    },
  },
  PRICE: {
    MONTHLY: '99',
    YEARLY: '695'
  },
  SUBSCRIPTION : {
    TYPES: {
      WEEKLY: 'Veckoprenumeration',
      MONTHLY: 'Månadsprenumeration',
      QUARTERLY: 'Kvartalsprenumeration',
      YEARLY: 'Årsprenumeration'
    },
    TYPES_SHORT : {
      WEEKLY: 'v',
      MONTHLY: 'mån',
      QUARTERLY: 'kvartal',
      YEARLY: 'år'
    }, 
    DURATION: {
      YEARLY: 'År',
      MONTHLY: 'Månad'
    },
    SINGULAR : {
      WEEKLY: 'veckan',
      MONTHLY: 'månaden',
      QUARTERLY: 'kvartalet',
      YEARLY: 'året',
    },
    PLURAL : {
      WEEKLY: 'veckor',
      MONTHLY: 'månader',
      QUARTERLY: 'kvartal',
      YEARLY: 'år',
    },
  },

  SUBSCRIPTION_TYPES: {
    WEEKLY: 'Veckoprenumeration',
    MONTHLY: 'Månadsprenumeration',
    QUARTERLY: 'Kvartalsprenumeration',
    YEARLY: 'Årsprenumeration'
  },
  SUBSCRIPTION_TYPES_SHORT: {
    WEEKLY: 'v',
    MONTHLY: 'mån',
    QUARTERLY: 'kvartal',
    YEARLY: 'år'
  },
  SUBSCRIPTION_DURATION: {
    YEARLY: 'År',
    MONTHLY: 'Månad'
  },
  PERIOD_SINGULAR : {
    WEEKLY: 'veckan',
    MONTHLY: 'månaden',
    QUARTERLY: 'kvartalet',
    YEARLY: 'året',
  },
  PERIOD_PLURAL : {
    WEEKLY: 'veckor',
    MONTHLY: 'månader',
    QUARTERLY: 'kvartal',
    YEARLY: 'år',
  },
  MONTHS: [
    'januari', 
    'februari', 
    'mars', 
    'april', 
    'maj', 
    'juni', 
    'juli', 
    'augusti', 
    'september', 
    'oktober', 
    'november', 
    'december'
  ]
};
