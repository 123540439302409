module.exports = {
  GTM_ID: 'GTM-T353HWZ',
  // GTM_HOST_ORIGIN: 'https://gtm.lylli.de', // Not supported in gatsby-config.js
  ICON: '🇩🇪',
  SITE_URL: 'https://account.lylli.de',
  CURRENCY: '€',
  STORELOGOS: {
    APPLE: {
      alt: 'App Store logo',
      href: 'https://itunes.apple.com/se/app/id1036395238',
      imageIdSuffix: '-app-store-image',
      linkIdSuffix: '-app-store-link',
    },
    GOOGLE: {
      alt: 'Google Play logo',
      href: 'https://play.google.com/store/apps/details?id=se.boksnok.android',
      imageIdSuffix: '-google-play-image',
      linkIdSuffix: '-google-play-link',
    },
    APPLE_LIGHT: {
      alt: 'App Store logo',
      href: 'https://itunes.apple.com/se/app/id1036395238',
      imageIdSuffix: '-app-store-image',
      linkIdSuffix: '-app-store-link',
    },
    GOOGLE_LIGHT: {
      alt: 'Google Play logo',
      href: 'https://play.google.com/store/apps/details?id=se.boksnok.android',
      imageIdSuffix: '-google-play-image',
      linkIdSuffix: '-google-play-link',
    },
  },
  PRICE: {
    MONTHLY: '6,90',
    YEARLY: '55'
  },
  SUBSCRIPTION : {
    TYPES: {
      WEEKLY: 'Wochenabonnement',
      MONTHLY: 'Monatsabonnement',
      QUARTERLY: 'Quartalsabonnement',
      YEARLY: 'Jahresabonnement'
    },
    TYPES_SHORT : {
      WEEKLY: 'Woche',
      MONTHLY: 'Monat',
      QUARTERLY: 'Quartal',
      YEARLY: 'Jahr'
    }, 
    DURATION: {
      YEARLY: 'Jahr',
      MONTHLY: 'Monat'
    },
    SINGULAR : {
      WEEKLY: 'Woche',
      MONTHLY: 'Monat',
      QUARTERLY: 'Quartal',
      YEARLY: 'Jahr',
    },
    PLURAL : {
      WEEKLY: 'Wochen',
      MONTHLY: 'Monate',
      QUARTERLY: 'Quartale',
      YEARLY: 'Jahre',
    },
  },
  SUBSCRIPTION_TYPES: {
    'WEEKLY': 'Veckoprenumeration',
    'MONTHLY': 'Monatsabonnement',
    'QUARTERLY': 'Kvartalsprenumeration',
    'YEARLY': 'Jahresabonnement'
  },
  SUBSCRIPTION_TYPES_SHORT: {
    'WEEKLY': 'v',
    'MONTHLY': 'Monat',
    'QUARTERLY': 'Quartale',
    'YEARLY': 'Jahr'
  },
  PERIOD_SINGULAR : {
    WEEKLY: 'veckan',
    MONTHLY: 'månaden',
    QUARTERLY: 'kvartalet',
    YEARLY: 'året',
  },
  PERIOD_PLURAL : {
    WEEKLY: 'veckor',
    MONTHLY: 'månader',
    QUARTERLY: 'kvartal',
    YEARLY: 'år',
  },
  MONTHS: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ]
};
