import { combineReducers } from 'redux';

import { getDateText, getDateTextFromMS, getRelativeDateText } from '../lib/utils';
import { USER } from './actionTypes';

const INITIAL_STATE = Object.freeze({
  campaign: {
    isLoading: false,
    details: null,
  },
  user: {
    userIsLoaded: false,
    userIsLoggedIn: null,

    data: null,
    email: null,
    accountSince: null,
    debtUntil: null,
    periodExpiryDate: null,

    paymentInfo: false,
    subscriptionInfo: false,
    subscriptionText: '',
  },
});

const user = (state = INITIAL_STATE.user, action) => {
  const { type, payload } = action;

  switch (type) {
    case USER.LOADED: {
      return {
        ...state,
        userIsLoggedIn: false,
        userIsLoaded: true,
      };
    }
    case USER.LOGGED_IN:
    case USER.DATA_LOADED: {
      const extra = {};

      try {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ userId: payload._id });
      } catch (ex) {
        console.error(ex);
      }

      if (payload.paymentInfo) {
        const { debt = 0, debtUpdatedAt = Date.now(), paymentType, periodExpiryDate } = payload.paymentInfo;

        extra.periodExpiryDate = getDateTextFromMS(periodExpiryDate);
        extra.paymentType = paymentType || null;

        if (debt > 0) {
          extra.periodExpiryDate = getDateTextFromMS(debt + debtUpdatedAt);
          extra.debtUntil = getRelativeDateText(new Date(debt + debtUpdatedAt));
        }
      }

      return {
        ...state,
        userIsLoaded: true,
        userIsLoggedIn: true,
        data: payload,
        email: payload.email,
        accountSince: getDateText(new Date(payload.timestamp_created)),
        ...extra,
        userId: payload._id,
      };
    }
    case USER.PAYMENT_INFO_LOADED: {
      return {
        ...state,
        paymentInfo: payload,
      };
    }
    case USER.SUBSCRIPTION_INFO_LOADED: {
      let text = '';
      if (payload && payload.subscriptionType) {
        console.log('We hacve info', payload);
        if (payload.isCanceled) {
          text = `Ditt konto är aktivt till ${payload.currentPeriodEndsAt}. Därefter kommer det inaktiveras.`
        } else {
          text = `Du har en ${payload.subscriptionType}. Den förlängs ${payload.currentPeriodEndsAt} med en kostnad på ${payload.nextPaymentAmount}.`
        }
      }
      return {
        ...state,
        subscriptionInfo: payload,
        subscriptionText: text,
      };
    }
    case USER.LOGGED_OUT: {
      return {
        ...state,
        userIsLoaded: true,
        userIsLoggedIn: false,
        data: null,
        email: null,
        accountSince: null,
        debtUntil: null,
      };
    }
    case USER.EMAIL_UPDATED: {
      return {
        ...state,
        data: { ...state.data, email: payload },
        email: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default combineReducers({ user });
